import React from 'react'
import H1 from './H1'
import Slider from 'react-infinite-logo-slider';

function Clients() {
  
  
    return (
    <div className='w-full py-10'>
      <div className="flex w-full justify-center">

      <H1 text="Zaufali nam" />
      </div>
      <div className="py-10">
      <Slider
            width="250px"
            duration={40}
            pauseOnHover={true}
            blurBorders={false}
            blurBoderColor={'#fff'}
        >
            
            <Slider.Slide>
                <img src="/img/clients/budo.png" alt="any2" className='w-36' />
            </Slider.Slide>
            <Slider.Slide>
                <img src="/img/clients/galeria-solna.jpg" alt="any2" className='w-36' />
            </Slider.Slide>
            <Slider.Slide>
                <img src="/img/clients/karszew.jpg" alt="any2" className='w-36' />
            </Slider.Slide>
            <Slider.Slide>
                <img src="/img/clients/qra.jpg" alt="any2" className='w-36' />
            </Slider.Slide>
            <Slider.Slide>
                <img src="/img/clients/emerco.svg" alt="any2" className='w-36' />
            </Slider.Slide>
        </Slider>
        </div>
    </div>
  )
}

export default Clients