import React from 'react'
import HeadButton from './HeadButton'
import ScrollAnimationWrapper from './ScrollAnimationWraper'

function Head() {
  return (
      //  <div className="bg-color1">
    <div className="container mx-auto w-full py-10  grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-x-20 gap-10">
        <ScrollAnimationWrapper><HeadButton title="Dmuchańce" text="Odkryj naszą bogatą ofertę dmuchanych atrakcji dla najmłodszych! Znajdziesz tutaj tory przeszkód, zjeżdżalnie, oraz wiele innych dmuchanych atrakcji." bgColor="#3E77BC" link="/dmuchance#scroll"/></ScrollAnimationWrapper>
        <ScrollAnimationWrapper><HeadButton title="Animacje" text="W tej sekcji znajdziesz animacje, które nie tylko bawią, ale również uczą w zabawny i przystępny sposób." bgColor="#F2AE1C" link="/animacje"/></ScrollAnimationWrapper>
        <ScrollAnimationWrapper><HeadButton title="Pikniki i imprezy firmowe" text="Poznaj nasze kompleksowe usługi organizacji eventów, gdzie dbamy o każdy detal, aby stworzyć niepowtarzalne chwile." bgColor="#30BAD8" link="/pikniki"/></ScrollAnimationWrapper>
        <ScrollAnimationWrapper><HeadButton title="Mini Gastro" text="Nasza zakładka Mini Gastro to miejsce, gdzie kolorowe kubeczki pełne słodyczy zapełnią się uśmiechami maluchów." bgColor="#ED2891" link="gastro"/></ScrollAnimationWrapper>
        <ScrollAnimationWrapper><HeadButton title="Pozostałe atrakcje" text="Byk rodeo, czy Eurobangee." bgColor="#00B30B" link="pozostale"/></ScrollAnimationWrapper>
        <ScrollAnimationWrapper><HeadButton title="Warsztaty" text="Zajecia praktyczne i manualne." bgColor="#7A5DF1" link="warsztaty"/></ScrollAnimationWrapper>
    </div>
    // </div>
  )
}

export default Head