import React from 'react'
import H1 from './H1'
import Cta from './Cta'
import { useEffect } from 'react'

function SubSite({title, desc, photos=[], id}) {
  
  useEffect(()=>{
    if (window.innerWidth <= 768) {
      // Przewijanie strony o 400px w dół
      window.scrollBy(0, 400);
    }
  },[])
  
  
  return (
    <>
    <div className='container mx-auto flex flex-col my-10'>
        <div className="flex flex-1 flex-col">
            <H1 text={title} id={id}/>
            <div className="py-5">
                {desc}
            </div>
            
        </div>
        <div className="flex flex-col w-full space-y-10">
            {(photos.length > 0) && photos.map((element, index) => (
              <div className='flex flex-col' key={index}>
              {element.photo ?
              <img
                src={`/img/${element.photo}`}
                alt={element.photo}
                className="rounded-xl lg:max-w-[700px] mx-auto"
              />
              :
              <div className='py-10 italic'>{`Tu wkrótce powjawi się zdjęcie ${element.title ? element.title : ''}`}</div>
              }
              <div className='flex pt-4 pb-1 text-xl font-semibold'>{element.title}</div>
              <div className='flex'>{element.desc}</div>
                </div>
            ))}
          </div>
       
    </div>
     <Cta
     title="519-383-289"
     subtitle="zadzwoń i dowiedz się wiecej"
     bgColor="#3E77BC"
     className={"bg-color3 bg-opacity-80 mb-10 text-[#fff]"}
     link="tel:+48519383289"
   />
   </>
  )
}

export default SubSite