// import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import './styles.css';

// import required modules
import { Autoplay } from 'swiper/modules';

function Gallery() {
  return (
    <div>
        <Swiper
        slidesPerView={1}
        spaceBetween={5}
        loop={true}
      
        autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 5,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 5,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 5,
          },
        }}
        modules={[Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide><img src="./img/slider1.jpg" alt="slider_image" /></SwiperSlide>
        <SwiperSlide><img src="./img/slider2.jpg" alt="slider_image" /></SwiperSlide>
        <SwiperSlide><img src="./img/slider3.jpg" alt="slider_image" /></SwiperSlide>
        <SwiperSlide><img src="./img/slider4.jpg" alt="slider_image" /></SwiperSlide>
        <SwiperSlide><img src="./img/slider5.jpg" alt="slider_image" /></SwiperSlide>
        <SwiperSlide><img src="./img/slider6.jpg" alt="slider_image" /></SwiperSlide>    
        <SwiperSlide><img src="./img/slider7.jpg" alt="slider_image" /></SwiperSlide>    
        <SwiperSlide><img src="./img/slider8.jpg" alt="slider_image" /></SwiperSlide>    
        <SwiperSlide><img src="./img/slider9.jpg" alt="slider_image" /></SwiperSlide>    
        <SwiperSlide><img src="./img/slider10.jpg" alt="slider_image" /></SwiperSlide>    
      </Swiper>
    </div>
  )
}

export default Gallery