import React from "react";
import { useState } from "react";
import './Navbar.css'
import {
  FaFacebook,
  FaInstagram,
  FaBars,
  FaX,
  FaYoutube,
  FaEnvelope,
} from "react-icons/fa6";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";

const SocialIcon = ({ name }) => {
  switch (name) {
    case "facebook":
      return <FaFacebook />;
    case "instagram":
      return <FaInstagram />;
    case "youtube":
      return <FaYoutube />;
    case "mail":
      return <FaEnvelope />;
    default:
      return name;
  }
};

function Navbar({ menu }) {
  const [toggle, setToggle] = useState(true);
  return (
      <div className="fixed w-full z-50">
        <div className="container mx-auto justify-between items-center flex text-[20px] py-5 px-5 lg:px-0">
          <div className="w-[100px]">
            <a href="/">
            <img src="/img/logo.svg" alt="logo" width="100%" height="100%" />
            </a>
          </div>

          <div className="justify-between items-center flex-1 ml-20 hidden lg:flex">
            <ul className="flex space-x-16 cursor-pointer">
              {menu.links.map((element, index) => {
                const isHashLink = element.link.includes('#');
                const LinkComponent = isHashLink ? HashLink : Link;
                return (
                  <li key={index} className="underline-from-center flex-shrink-0 text-2xl font-titan uppercase opacity-80">
                    <LinkComponent to={element.link}
                    // scroll={el => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}
                    >{element.name}</LinkComponent>
                  </li>
                );
              })}
            </ul>

            <ul className="flex cursor-pointer text-2xl space-x-3 items-center">
              {menu.social.map((element, index) => {
                return (
                  <li
                    key={index}
                    className="hover:scale-125 transition transform-origin-bottom">
                    <a href={element.link}>
                      <SocialIcon name={element.name} />
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>

          <div
            className={`flex lg:hidden cursor-pointer ${
              toggle ? "" : "fade-in"
            }`}
            onClick={() => setToggle((toggle) => !toggle)}>
            <div className="text-3xl z-50">{toggle ? <FaBars /> : <FaX />}</div>
            <div
              className={`${
                toggle ? "hidden" : "flex flex-col"
              } justify-between absolute w-screen h-screen bg-color3 bg-opacity-90 left-0 top-0 px-5 py-8`}>
              <ul className=" flex flex-col text-4xl space-y-10 cursor-pointer justify-center w-full h-full items-center font-titan uppercase">
                {menu.links.map((element, index) => {
                  return (
                    <li
                      key={index}
                      className={`${
                        toggle ? "menu-item-hidden" : "bounce-in-up"
                      }`}
                      style={{ animationDelay: `${index * 0.1}s` }}>
                      <a href={element.link}>{element.name}</a>
                    </li>
                  );
                })}
              </ul>
              <ul className="flex cursor-pointer text-2xl space-x-7 items-center w-ful justify-center">
                {menu.social.map((element, index) => {
                  return (
                    <li
                      key={index}
                      className={`hover:scale-105 transition ${
                        !toggle ? "bounce-in-up" : "hidden"
                      }`}
                      style={{ animationDelay: `${index * 0.1}s` }}>
                      <a href={element.link}>
                        <SocialIcon name={element.name} />
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>

          </div>
        </div>
      </div>
  );
}

export default Navbar;
