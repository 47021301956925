import React, { useState } from "react";
import H1 from "./H1";
import ReactMapGL, { Marker } from "react-map-gl";
import 'mapbox-gl/dist/mapbox-gl.css';
import ScrollAnimationWrapper from "./ScrollAnimationWraper";
import { FaLocationDot } from "react-icons/fa6";

function Contact({id}) {
  const [enableZoom, setEnableZoom] = useState(false);

  const handleMapClick = () => {
    setEnableZoom(true);
  };

  const handleMouseOut = () => {
    setEnableZoom(false);
  };

//   const [viewport, setViewport] = useState({
//     latitude: 52.237049,
//     longitude: 21.017532,
//     zoom: 10,
//     width: '100%',
//     height: '100%',
// });

// const markers = [
//     { latitude: 52.237049, longitude: 21.017532 },
//     // Dodaj tutaj więcej markerów
// ];



  return (
    <div className="w-full flex h-[1200px] flex-col justify-center py-10 relative bg-color2 bg-opacity-70" id={id}>
      <div className="flex container mx-auto justify-center">
        <H1 text="tu jesteśmy" />
      </div>
      <div className="flex flex-col md:flex-row container mx-auto my-10 space-y-10 md:space-y-0">
        <ScrollAnimationWrapper className="flex flex-col flex-1 space-y-2 items-center">
          <div className="text-6xl"><FaLocationDot /></div>
          <div className="text-2xl">Park Podolski</div>
          <p>al. Marsz. Śmigłego-Rydza 68</p>
          <p>90-001 Łódź</p>
        </ScrollAnimationWrapper>
        <ScrollAnimationWrapper className="flex flex-col flex-1 space-y-2 items-center">
        <div className="text-6xl"><FaLocationDot /></div>
          <div className="text-2xl">Park Na Zdrowiu</div>
          <p>ul. Konstantynowska 3/5</p>
          <p>90-001 Łódź</p>
        </ScrollAnimationWrapper>
      </div>
         

      {/* <ReactMapGL
      mapboxApiAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
            {...viewport}
            onViewportChange={viewport => setViewport(viewport)}
            scrollZoom={enableZoom}
            onClick={handleMapClick}
            onMouseOut={handleMouseOut}
            mapStyle="mapbox://styles/mapbox/streets-v9"
        >
            {markers.map((marker, index) => (
                <Marker
                    key={index}
                    latitude={marker.latitude}
                    longitude={marker.longitude}
                >
                    <img
            src="/img/logo.svg"
            alt="Marker"
            style={{ width: "50px", height: "50px" }}
            className="animate-bounce"
          />
                </Marker>
            ))}
        </ReactMapGL> */}








      <ReactMapGL
        mapLib={import("mapbox-gl")}
        // className="absolute w-full h-full"
        initialViewState={{
          longitude: 19.455509,
          latitude: 51.757991,
          zoom: 11.5,
        }}
        // onViewportChange={viewport => setViewport(viewport)}
        // style={{ width: '100%', height: '100%' }}
        mapStyle="mapbox://styles/mapbox/streets-v9"
        scrollZoom={enableZoom}
        onClick={handleMapClick}
        onMouseOut={handleMouseOut}>
        <Marker longitude={19.412405} latitude={51.770139}>
          <img
            src="/img/logo.svg"
            alt="Marker"
            style={{ width: "50px", height: "50px" }}
            className="animate-bounce"
          />
        </Marker>
        <Marker longitude={19.500717} latitude={51.742158}>
          <img
            src="/img/logo.svg"
            alt="Marker"
            style={{ width: "50px", height: "50px" }}
            className="animate-bounce"
          />
        </Marker>
      </ReactMapGL>
      <div className="w-full flex flex-col container mx-auto items-center justify-center text-center">
        <H1 text="Dane kontaktowe" />
        <p className="text-xl">Skaczące Brzdące</p>
        <p>Iwomar Mariusz Wlazło</p>
        <p>91-129 Łódź, ul. K. Cedry 4/38</p>
        <p>NIP: 725 19 44 989</p>
        <p>mBank 78 1140 2004 0000 3502 8427 2637</p>
      </div>
    </div>
  );
}

export default Contact;
