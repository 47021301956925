import Header from "./components/Header";
import Head from "./components/Head";
import Navbar from "./components/Navbar";
import Clients from "./components/Clients";
import Cta from "./components/Cta";
import Footer from "./components/Footer";
import Main from "./components/Main";
import Contact from "./components/Contact";
import Reviews from "./components/Reviews";
import Gallery from "./components/Gallery";
import StickyMobileFooter from "./components/StickyMobileFooter";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import SubSite from "./components/SubSite";
import News from "./components/News";
import { CanonicalLink } from "./components/CannonicalLink";

function App() {
  return (
    <div className="overscroll-y-none bg-custom-bg bg-repeat">
      <BrowserRouter>
      {/* <BrakePoint /> */}
      <CanonicalLink />
        <StickyMobileFooter />
        <Navbar
          menu={{
            links: [
              {
                link: "/#onas",
                name: "O nas",
              },
              {
                link: "/aktualnosci",
                name: "Aktualności",
              },
              {
                link: "/#kontakt",
                name: "Znajdź nas",
              },
            ],
            social: [
              {
                link: "https://www.facebook.com/profile.php?id=100089166303101",
                name: "facebook",
              },
              {
                link: "#",
                name: "instagram",
              },
              {
                link: "mailto:skaczace.brzdace@onet.pl",
                name: "mail",
              },
              {
                link: "https://www.youtube.com/@skaczacebrzdace",
                name: "youtube",
              },
            ],
          }}
        />
        <Header />
        <Routes>
          <Route
            exact
            path="/"
            element={
              <>
                <Head />
                <Main id="onas"/>
                <Reviews />
                <Cta
                  title="dodaj swoją opinię"
                  subtitle="będzie nam naprawdę miło"
                  className={"bg-color1 bg-opacity-50 text-[#fff]"}
                  link="https://www.google.com/maps/place/Dmuchańce+Skaczące+Brzdące+-+wynajem+dmuchanych+zjeżdzalni+Łódź/@51.7854501,19.3874255,17z/data=!4m8!3m7!1s0x8a3f0a73e5c64d89:0x9e0eae9c92fa7d40!8m2!3d51.7854501!4d19.3900004!9m1!1b1!16s%2Fg%2F11l6_1txqz?entry=ttu"
                />
                <Clients />
                <Contact id="kontakt"/>
                <Gallery />
                <Cta
                  title="519-383-289"
                  subtitle="zadzwoń i dowiedz się wiecej"
                  className={"bg-color3 bg-opacity-80 my-20 text-[#fff]"}
                  link="tel:+48519383289"
                />
              </>
            }
          />
          <Route
            path="/dmuchance"
            element={
              <SubSite
              id={"scroll"}
                title="Dmuchańce"
                desc="Lista naszych dmuchańców:"
                photos={[
                  {
                    photo: "dmuchance-piesek.jpg",
                    title: "Zjeżdzalnia Piesek",
                    desc: "Wymiary: 8.5m x 5m wysokość 6m, waga: 150kg, czas rozłożenia: 30min, pobór energii: 1.1KW"
                  },
                  {
                    photo: "dmuchance-zoo.jpg",
                    title: "Zjeżdzalnia Zoo",
                    desc: "Wymiary: 8.5 m x 5m wysokość 6m, waga: 150kg, czas rozłożenia: 30min, pobór energii: 1.1KW"
                  },
                  {
                    photo: "dmuchance-basen.jpg",
                    title: "Suchy basen z piłkami",
                    desc: "Wymiary: 4m x 3m wysokość 2.5m, waga: 50kg, czas rozłożenia: 20 min, pobór energii: 1.1KW"
                  },
                  {
                    photo: "dmuchance-jungla.jpg",
                    title: "Plac zabaw Jungla",
                    desc: "Wymiary: 8m x 6m wysokość 3m, waga: 170kg, czas rozłożenia: 30 min, pobór energii: 1.1KW"
                  },
                  {
                    photo: "dmuchance-kule.jpg",
                    title: "Tor przeszkód Mega Kule",
                    desc: "Wymiary: 13.5m x 5.5m wysokość 4m, waga: 250kg, czas rozłożenia: 50min, pobór energi: 2 x 1.1KW"
                  },
                  {
                    photo: "dmuchance-tor.jpg",
                    title: "Tor przeszkód Ninja Warrior 40m",
                    desc: "Wymiary: 40m x 5m wysokość 5m, waga: 500kg, czas rozłożenia: 2h, pobór energii: 6 x 1.1KW"
                  },
                  {
              
                    title: "Tor przeszkód Ninja Warrior 20m",
                    desc: "Wymiary: 20m x 5m wysokość 5m, waga: 250kg, czas rozłożenia: 1h, pobór energii: 3 x 1.1KW"
                  },
                  {
                    photo: "dmuchance-tygrysek.jpg",
                    title: "Duża zjeżdzalnia Tygrysek",
                    desc: "Wymiary: m x m wysokość m, waga: 220kg, czas rozłożenia: 50min, pobór energii: 1 x 1.1KW"
                  },
                  {
                    photo: "dmuchance-traktor.jpg",
                    title: "Duża zjeżdzalnia Traktor",
                    desc: "Wymiary: 11m x 7m wysokość 7.5m, waga: 300kg, czas rozłożenia: 30min, pobór energii: 1 x 1.1KW"
                  },
                  {
                    photo: "dmuchance-zjezdzalnia-z-przeszkodami.jpg",
                    title: "Zjeżdzalnia z przeszkodami",
                    desc: "Wymiary: 5m x 14m wysokość 3.5m, waga: 200kg,czas rozłożenia: 40min, pobór energi: 2 x 1.1KW"
                  },
                ]}
              />
            }
          />
          <Route
            path="/animacje"
            element={
              <SubSite
                title="Animacje"
                desc="Zajrzyj tu wkrótce i poznaj nasze animiacje."
                
              />
            }
          />
          <Route
            path="/pikniki"
            element={
              <SubSite
                title="Pikniki i imprezy firmowe"
                desc="Z dumą prezentujemy naszą usługę kompleksowej organizacji pikników, eventów i imprez firmowych, wzbogaconych o dmuchane atrakcje, animacje, usługi gastronomiczne oraz zabezpieczenie w prąd. Przejmujemy inicjatywę, aby Twoje wydarzenie było nie tylko udane, ale i niezapomniane! Od pierwszego pomysłu po moment zakończenia imprezy, oferujemy kompleksową obsługę. Pomagamy w planowaniu, koordynujemy działania podczas wydarzenia i dbamy o każdy detal, abyś mógł skupić się na radości z udanej imprezy. Zaufaj nam, a Twoje wydarzenie stanie się niezapomnianą chwilą pełną uśmiechów i pozytywnych wspomnień. Skontaktuj się z nami już teraz, aby rozpocząć planowanie swojej wyjątkowej imprezy!"
                photos={[
                  {
                    photo: "pikniki-1.jpg",
                    desc: "Dla nas każde wydarzenie to szansa na dostarczenie niepowtarzalnej rozrywki. Nasze dmuchane atrakcje to nie tylko klasyczne zamki, ale także innowacyjne rozwiązania, takie jak Mega Kule, Dmuchane Tor Przeszkód czy gigantyczne zjeżdżalnie. Każda z atrakcji jest starannie dostosowana do standardów bezpieczeństwa."
                  },
                  {
                    photo: "pikniki-2.jpg",
                    desc: "Zapewniamy różnorodne animacje, które dostarczą rozrywki nie tylko najmłodszym, ale i wszystkim uczestnikom. Twórcze warsztaty, malowanie twarzy, pokazy iluzji czy konkursy z nagrodami - nasze animacje nadają imprezie dynamiczny i przyjemny charakter."
                  },
                  {
                    photo: "pikniki-3.jpg",
                    desc: "Zapewniamy kompleksowe zabezpieczenie w prąd, dbając o ciągłość imprezy. Nasze zespoły techniczne nadzorują sprzęt elektryczny, aby wszystko działało sprawnie, eliminując ewentualne problemy."
                  },
                ]}
              />
            }
          />
          <Route
            path="/gastro"
            element={
              <SubSite
                title="Mini Gastro"
                desc=""
                photos={[
                  {
                    photo: "gastro-wata.png",
                    desc: "Wata cukrowa"
                  },
                  {
                    photo: "gastro-popcorn.png",
                    desc: "Popcorn"
                  },
                  {
                    photo: "gastro-kawa.png",
                    desc: "Kawa i herbata"
                  },
                  {
                    photo: "gastro-zelki.png",
                    desc: "Żelki"
                  },
                  {
                    photo: "gastro-ziemniak.jpg",
                    desc: "Zakręcony ziemniak"
                  },
                ]}
              />
            }
          />
          <Route
            path="/pozostale"
            element={
              <SubSite
                title="Pozostałe atrakcje"
                desc=""
                photos={[
                  {
                    photo: "pozostale-bungee.jpg",
                    title: "Euro Bungee",
                    desc: "Wymiary 9m x 9m wysokość 8m, czas rozłożenie 45min, waga 750 kg, pobór energii: 4 x 500W."
                  },
                  {
                    photo: "pozostale-eliminator.jpg",
                    title: "Eliminator",
                    desc: "Wymiary: 8m x 8m wysokość 1.6m, waga: 300kg, czas rozłożenia: 50min, pobór energii: 1 x 2.4KW"
                  },
                  {
                    photo: "pozostale-byk.jpg",
                    title: "Byk Rodeo",
                    desc: "Wymiary: 6m x 6m, waga: 350kg, czas rozłożenia: 50min, pobór energii: 1 x 1.1KW"
                  },
                ]}
              />
            }
          />
          <Route
            path="/warsztaty"
            element={
              <SubSite
                title="Warsztaty"
                desc=""
                photos={[
                  {
                    photo: "warsztaty-sol.jpg",
                    title: "Sól do kąpieli",
                    desc: "Naturalny kosmetyk tworzony w dekoracyjnych, szklanych butelkach. Doskonale sprawdzi się również jako prezent"
                  },
                  {
                    photo: "warsztaty-torby.jpg",
                    title: "Malowanie toreb i czapek",
                    desc: "Indywidualne zdobienia za pomocą specjalnych farb i zdobników"
                  },
                  {
                    photo: "warsztaty-las.jpg",
                    title: "Las w słoiku",
                    desc: "Warsztat przyrodniczy, w którym dzieci wykonują tematyczny las w słoiku na każdą okazję"
                  },
                  {
                    photo: "warsztaty-swiece.jpg",
                    title: "Świece sojowe",
                    desc: "Świece wykonane z wosku sojowego. Gotowa świeca imituje słodki, pełen kolorów deser"
                  },
                  {
                    photo: "warsztaty-lod.jpg",
                    title: "Suchy lód",
                    desc: "Eksperymenty z udziełem dwutlenka węgla"
                  },
                  {
                    photo: "warsztaty-czekolada.jpg",
                    title: "Warsztaty czekoladowe",
                    desc: "Podczas zajęć dzieci samodzielnie przygotowują czekoladę, którą przelewają do form i ozdabiają ulubionymi dodatkami"
                  },
                  {
                    photo: "warsztaty-flower.jpg",
                    title: "Flower box",
                    desc: "Podczas zajęć dzieci tworzą oryginalne bukiety kwiatowe umieszcane w pudełkach"
                  },
                ]}
              />
            }
          />
          <Route
          path="/aktualnosci"
          element={<News />}
          />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
