import React from "react";
import { ReactComponent as MySvg } from "./headerButtonBg.svg";

function HeadButton({
  title = "",
  text = "",
  link = "#",
  bgColor = "#F2AE1C",
}) {
  return (
    <div
      className={`hover:animate-custom-bounce flex flex-col items-center justify-center h-full w-full text-white 
      text-lg p-12 sm:p-24 md:p-32  lg:p-20 xl:p-10 opacity-70 relative ${bgColor}`}>
      <MySvg
        className="absolute top-0 left-0 w-full h-full -rotate-4"
        style={{ color: bgColor }}
      />
      <div className="relative z-10 font-titan uppercase 
       text-xl sm:text-4xl xl:text-2xl 2xl:text-4xl text-center sm:my-2 pt-5 text-[#fff]">
        {title}
      </div>
      <div className="relative z-10 
      text-base sm:text-xl lg:text-base xl:text-base 2xl:text-xl text-center text-wrap text-[#fff]">
        {text}
      </div>
      <a href={link} className="cursor-pointer">
        <div className="relative z-10 text-[#fff] text-xl 
        font-titan mt-2 md:mt-4 xl:mt-2 border rounded-full shadow-md flex items-center justify-center px-6 py-2">
          sprawdź
        </div>
      </a>
    </div>
  );
}

export default HeadButton;
