import React, { useEffect, useState } from "react";
import H1 from "./H1";
import { Link } from "react-router-dom";
import ScrollAnimationWrapper from "./ScrollAnimationWraper";

function News() {
  const [news, setNews] = useState([]);
  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await fetch("/news.json");
        const data = await response.json();
        setNews(data);
      } catch (error) {
        console.error("Error fetching news:", error);
      }
    };

    fetchNews();
  }, []);

  return (
    <div className="container mx-auto">
      <H1 text="Aktualności" />
      {news.map((oneNews, index) => (
        <div
          className={`flex flex-col my-10 ${
            index % 2 === 0 ? `lg:flex-row` : `lg:flex-row-reverse`
          }`}
          key={index}>
          <ScrollAnimationWrapper
            className={`flex flex-1 flex-col ${
              index % 2 === 0 ? `lg:pr-10` : `lg:ps-10`
            }`}>
            <div className="text-2xl font-titan">{oneNews.title}</div>
            <div className="font-bold ps-2">{oneNews.subtitle}</div>
            <div className="pt-5 mb-4" dangerouslySetInnerHTML={{ __html: oneNews.desc }}></div>
            {oneNews.link && (
              <Link
                to={oneNews.link}
                className="text-[#fff] text-xl bg-color1 mr-auto
        font-titan mb-4 border rounded-full shadow-md flex items-center justify-center px-6 py-2">
                więcej
              </Link>
            )}
          </ScrollAnimationWrapper>

          <div className="flex-1 grid grid-cols-1 md:grid-cols-2 gap-1">
            {oneNews.photos.map((photo, index) => (
              <img
                src={`/img/aktualnosci/${photo}`}
                alt={photo}
                key={index}
                className={`rounded-xl ${
                  oneNews.photos.length % 2 !== 0 &&
                  index === oneNews.photos.length - 1
                    ? "md:col-span-2 lg:col-span-2"
                    : ""
                }`}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default News;
