import React, { useRef, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';

const ScrollAnimationWrapper = ({ children, className, keyProp }) => {
  const controls = useAnimation();
  const ref = useRef(null);

  useEffect(() => {
    const currentRef = ref.current; // Przypisanie ref.current do zmiennej lokalnej
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          controls.start("visible");
        } else {
          controls.start("hidden");
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.5
      }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [controls]);

  const classNames = className ? `${className}` : '';
  return (
    <motion.div
      key={keyProp}
      ref={ref}
      animate={controls}
      initial="hidden"
      variants={{
        visible: { opacity: 1, scale: 1, transition: { duration: 0.5 } },
        hidden: { opacity: 0, scale: 0.8 }
      }}
      className={classNames}
    >
      {children}
    </motion.div>
  );
};

export default ScrollAnimationWrapper;
