import React from "react";
import ScrollAnimationWrapper from "./ScrollAnimationWraper";

function Cta({ title, subtitle, className, link}) {
  const classNames = className ? `${className}` : '';
  return (
    <div
      className={`w-full flex flex-col justify-center items-center pt-10 pb-14 px-10 shadow-md ${classNames}`}
      style={{ 
            clipPath: 'polygon(0 0, 100% 0, 100% 90%, 50% 100%, 0 90%)'
      }}>
        <ScrollAnimationWrapper>
      <div className="fle text-center font-titan text-3xl lg:text-5xl py-2 lg:py-5 cursor-pointer">
      <a href={link}>{title}</a>
      </div>
      <div className="flex text-center justify-center text-base lg:text-xl px-10 font-titan opacity-90">
        {subtitle}
      </div>
      </ScrollAnimationWrapper>
    </div>
  );
}

export default Cta;
