import React from 'react'
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
} from "react-icons/fa6";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
    <div className='w-full bg-color4 bg-opacity-70'>
      <div className="flex container mx-auto justify-between items-start flex-col lg:flex-row py-10 space-y-10 lg:space-y-0">
        <div className="flex max-w-lg justify-start">
        Wierzymy, że relacje z ludźmi są kluczowe dla sukcesu w biznesie dlatego nasze podejście opiera się na wzajemnym zaufaniu, uczciwości i profesjonalizmie. Dążymy do tego, aby każdy klient czuł się doceniony i obsługiwany z najwyższą starannością.
        </div>
        <div className="flex flex-col justify-start">
          <div className='font-titan uppercase text-xl'>menu</div>
          <HashLink className = " hover:scale-110 transition" to="/#onas">O nas</HashLink>
          <Link className = " hover:scale-110 transition" to="/aktualnosci">Aktualności</Link>
          <Link className = " hover:scale-110 transition" to="/dmuchance">Dmuchańce</Link>
          <Link className = " hover:scale-110 transition" to="/animacje">Animacje</Link>
          <Link className = " hover:scale-110 transition" to="/pikniki">Pikniki i imprezy</Link>
          <Link className = " hover:scale-110 transition" to="/gastro">Mini gastro</Link>
          <Link className = " hover:scale-110 transition" to="/pozostale">Pozostałe animacje</Link>
          <Link className = " hover:scale-110 transition" to="/warsztaty">Warsztaty</Link>
        </div>
        <div className="flex flex-col justify-start">
        <div className='font-titan uppercase text-xl'>dane kontaktowe</div>
          <HashLink className = " hover:scale-110 transition" to="/#kontakt">91-129 Łódź, ul. K. Cedry 4/38</HashLink>
          <a className = " hover:scale-110 transition" href="tel:+48519383289">tel. 519-383-289</a>
          <a className = " hover:scale-110 transition" href="mailto:skaczace.brzdace@onet.pl">skaczace.brzdace@onet.pl</a>
          <div className='flex space-x-2 text-xl mt-2'>
            <a className = " hover:scale-110 transition" href="https://www.facebook.com/profile.php?id=100089166303101"><FaFacebook /></a>
            <a className = " hover:scale-110 transition" href="#"><FaInstagram /></a>
            <a className = " hover:scale-110 transition" href="https://www.youtube.com/@skaczacebrzdace"><FaYoutube /></a>
          </div>
        </div>
      </div>
    </div>
    <div className="w-full justify-center items-center bg-color2 bg-opacity-70 pb-28 md:pb-5 text-center py-4">
    <a href="https://emer.co" target="_blank" rel="noreferrer">emer.co </a>
    &copy; {document.title + " " + new Date().getFullYear()}
    </div>
    </>
  )
}

export default Footer