import React, { useState, useEffect } from "react";
import H1 from "./H1";
import { FaStar } from "react-icons/fa6";
import ScrollAnimationWrapper from "./ScrollAnimationWraper";

function Reviews() {
  const [reviews, setReviews] = useState([]);
  useEffect(() => {
    const fetchReviews = async () => {
      try {
        const response = await fetch("/reviews.json");
        const data = await response.json();
        setReviews(data);
      } catch (error) {
        console.error("Error fetching reviews:", error);
      }
    };

    fetchReviews();
  }, []);

  return (
    <div className="w-full bg-color1 py-10 bg-opacity-30">
      <div className="container mx-auto flex flex-col justify-center w-full items-center">
        <div className="flex">
            <H1 text="nasze opinie" />
            </div>
        <div className="container mx-auto w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-10">
          {reviews.map((review, index) => (
            <div key={index} className="flex w-full flex-col text-center p-5">
              <ScrollAnimationWrapper>
              <div className="flex">{review.text}</div>
              <div className="flex font-bold justify-center items-center mt-2">
                <FaStar />
                <FaStar />
                <FaStar />
                <FaStar />
                <FaStar />
                <div className="ms-2">{review.name}</div>
              </div>
              </ScrollAnimationWrapper>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Reviews;
