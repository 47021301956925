import React from "react";
import H1 from "./H1";
import ScrollAnimationWrapper from "./ScrollAnimationWraper";
import { FaRegCircleCheck } from "react-icons/fa6";

function Main({id}) {
  const featutes = [
    {
      title: "Współpraca na Terenie Całej Polski.",
      desc: "Jesteśmy gotowi obsłużyć Twoje wydarzenie niezależnie od lokalizacji.",
    },
    {
      title: "Szybki i prosty kontatk",
      desc: "Nasza firma stawia na bezproblemowy kontakt. Jesteśmy dostępni, gdy nas potrzebujesz.",
    },
    {
      title: "Profesjonalna obsługa",
      desc: "Nasz zespół zawsze dba o profesjonalizm, zapewniając Ci obsługę na najwyższym poziomie.",
    },
    {
      title: "Czysty i nowoczesny sprzęt",
      desc: "Nasze dmuchane atrakcje to gwarancja nowości i bezpieczeństwa dla dzieci.",
    },
    {
      title: "Elastyczne warunki współpracy",
      desc: "Dopasujemy się do Twoich potrzeb, oferując elastyczne warunki współpracy.",
    },
    {
      title: "Przeszkolona obsługa",
      desc: "Nasz personel jest przeszkolony, by zadbać o każdy detal i zapewnić bezpieczeństwo podczas wydarzenia.",
    },
    {
      title: "Ubezpieczenie NNW",
      desc: "Twój spokój jest dla nas ważny. Jesteśmy ubezpieczeni od nieszczęśliwych wypadków.",
    },
    {
      title: "Aktualne przeglądy urządzeń",
      desc: "Bezpieczeństwo dzieci jest dla nas priorytetem, regularnie przeglądamy i konserwujemy nasze urządzenia.",
    },
  ];

  return (
    <div className="bg-[#fff]"id={id}>
    <div className="container mx-auto w-full flex flex-col lg:flex-row items-center py-10 ">
      <div className="flex flex-col flex-1 items-center justify-center space-y-2">
        <H1 text="O nas" />
        <div className="flex w-full">
          Witajcie na stronie Skaczące Brzdące - Twoim Partnerem przy
          organizacji niezapomnianych atrakcjach dla Dzieci!
        </div>
        <div className="flex w-full">
          Skaczące Brzdące to dynamicznie rozwijająca się firma w dziedzinie
          wynajmu dmuchanych atrakcji dla dzieci na terenie całej Polski.
          Oferujemy bogaty wybór dmuchanych zjeżdżalni, placów zabaw, torów
          przeszkód oraz wszelkich atrakcji zapewniających niezapomniane chwile
          radości dla najmłodszych uczestników każdego wydarzenia.
        </div>
        <div className="flex w-full">
          Skaczące Brzdące to nie tylko dostawca dmuchanych atrakcji, ale także
          partner, gotów znaleźć rozwiązania na każde wyzwanie. Dążymy do
          zadowolenia klientów, co sprawia, że nasi klienci do nas wracają.
        </div>
        <div className="flex my-2 text-xl w-full py-4 font-titan">Dlaczego my?</div>
        <div className="flex w-full flex-col space-y-3">
          {featutes.map((feature, index) => {return(
          <ScrollAnimationWrapper className="flex w-full items-center" keyProp={index+"a"}>
            <div className="flex text-2xl mx-3 text-[#00B30B]">
              <FaRegCircleCheck />
            </div>
            <div className="flex flex-col">
              <div className="font-bold">{feature.title}</div>
              <div className="text-base">{feature.desc}</div>
            </div>
          </ScrollAnimationWrapper>
)})}
        </div>
      </div>
      <ScrollAnimationWrapper className="flex flex-1 items-center justify-center">
        <img src="/img/main.jpg" alt="main_image" className="object-contain" />
      </ScrollAnimationWrapper>
    </div>
    </div>
  );
}

export default Main;
