import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const CanonicalLink = () => {
  const location = useLocation();

  useEffect(() => {
    // Tworzenie lub aktualizacja elementu <link rel="canonical">
    const canonicalLink = document.querySelector("link[rel='canonical']") || document.createElement('link');
    canonicalLink.rel = 'canonical';
    // Ustawienie href na aktualny adres URL z użyciem location.pathname
    canonicalLink.href = window.location.origin + location.pathname;
    document.head.appendChild(canonicalLink);

    // Opcjonalnie, czyszczenie przy odmontowywaniu komponentu
    return () => {
      if (canonicalLink) {
        document.head.removeChild(canonicalLink);
      }
    };
  }, [location.pathname]); // Zależność od location.pathname powoduje aktualizację przy zmianie ścieżki

  return null; // Komponent nie renderuje niczego w DOM
};
