import React from 'react';

function Header() {
  return (
    <div className="flex w-screen" style={{ height: '80vh' }}>
      <img 
        src="/img/header.jpg" 
        alt="Header" 
        className="object-cover w-full"
        style={{
          clipPath: 'polygon(0 0, 100% 0, 100% 90%, 50% 100%, 0 90%)'
        }}
      />
    </div>
  );
}

export default Header;
