import React from 'react'
import { FaPhoneVolume, FaFacebook } from "react-icons/fa6";


function StickyMobileFooter() {
  return (
    <div className='fixed md:hidden flex bottom-5 w-full h-20 z-50 bg-color2 text-2xl font-titan uppercase align-middle border-t-2 border-b-2 opacity-90'>
        <a className="flex items-center w-full h-full container mx-auto justify-center" href="tel:+48519383289">
            <FaPhoneVolume /> 
            <div className='ms-3'>Kliknij i zadzwoń</div>
        </a>
        <a className='flex w-1/5 bg-color4 rounded-l-full justify-center items-center text-5xl text-[#fff]' href="https://www.facebook.com/profile.php?id=100089166303101"><FaFacebook /></a>
    </div>
    // <div className='fixed md:hidden bottom-5 w-full h-20 z-50 bg-color2 text-2xl font-titan uppercase align-middle border-t-2 border-b-2 opacity-90'>
    //     <a className="flex items-center w-full h-full container mx-auto justify-center" href="tel:+48519383289">
    //         <FaPhoneVolume /> 
    //         <div className='ms-3'>Kliknij i zadzwoń</div>
    //     </a>
    // </div>
  )
}

export default StickyMobileFooter